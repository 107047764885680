.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

.btn-custom-primary {
  background-color: #094780;
  color: white;
}

.btn-custom-primary:hover {
  border: solid #094780 1px;
  color: #094780;
}