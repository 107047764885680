.ag-theme-balham {
  --ag-header-foreground-color: #ffffff !important;
  --ag-header-background-color: #094780 !important;
  --ag-font-family: "Segoe UI" !important;

  .ag-header-group-cell {
    color: white !important;
  }

  .ag-header-cell .ag-icon-filter {
    color: white !important;
  }

  /* .ag-row-odd {
    background-color: #e7e6e6 !important;
  } */

  .ag-icon-menu {
    color: white !important;
  }
}

.ag-tooltip {
  background-color: #094780 !important;
  color: white !important;
  border-radius: 2px !important;
  padding: 10px 16px !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #cbd0d3 !important;
  transition: opacity 1s !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  -ms-border-radius: 2px !important;
  -o-border-radius: 2px !important;
  -webkit-transition: opacity 1s !important;
  -moz-transition: opacity 1s !important;
  -ms-transition: opacity 1s !important;
  -o-transition: opacity 1s !important;
}

.ag-left-aligned-cell {
  justify-content: flex-start !important;
}

.ag-right-aligned-cell {
  justify-content: flex-end !important;
}

.nav.nav-tabs .nav-item {
  height: 35px !important;
}

.nav.nav-tabs .nav-item .nav-link {
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
}

.custom-primary-text {
  color: #094780 !important;
}

.custom-border-top {
  border-top: 1px solid #094780 !important;
}

.custom-border-right {
  border-right: 1px solid #094780 !important;
}

.custom-border-bottom {
  border-bottom: 1px solid #094780 !important;
}

.custom-border-left {
  border-left: 1px solid #094780 !important;
}

.segoe-font {
  font-family: "Segoe UI" !important;
}
